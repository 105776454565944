.projectPage {
  width: 100%;
  min-height: 800px;
  background-color: var(--darker);
  color: var(--text);
  padding-top: 76px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectPage .mediaContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
  width: 720px;
  max-width: 80%;
}

@media only screen and (max-width: 560px) {
  .projectPage .mediaContent iframe{    
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.projectPage .mediaContent .no-carousel img{
  height: 280px;
  padding: 3px;
  background-color: var(--text);
  border-radius: 12px;
}

@media only screen and (max-width: 560px) {
  .projectPage .mediaContent .no-carousel img{
    height: 180px;
  }
}

.projectPage .content {
  width: 820px;
  max-width: 90%;
  margin: 16px;
}

.projectPage .header{
  text-align: center;
}

.projectPage .header h1 {
  margin: 0px;
  font-family: Chakra Petch;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.projectPage .content .body {
  margin: 16px;
  text-align: justify;
  font-size: 16px;
  font-family: Open Sans;
}

.projectPage .content .body-link {
  color: var(--text);
}

.projectPage .tech {
  border-top: 1px solid var(--dark);
  width: 900px;
  max-width: 80%;
  font-family: Chakra Petch;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 16px;
  margin-top: 0px;
}

.projectPage .tech-header {
  margin: 16px;
  margin-top: 32px;
}

.projectPage .tech-content {
  justify-content: space-around;
}

.projectPage .tech-images {
  transform: scale(0.8);
  overflow: visible;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  row-gap: 32px;
  justify-content: center;
  align-items: center;
}

.projectPage .tech-images div {
  border-radius: 16px;
  font-size: 24px;
  text-decoration: none;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.1s;
}

.projectPage .tech-images a {
  text-decoration: none;
  color: var(--text);
}

.projectPage .tech-images div:hover {
  background: var(--dark);
  border-radius: 16px;
  transition: 0.1s;
}

.projectPage .links {
  border-top: 1px solid var(--dark);
  margin: 16px;
  margin-top: 0px;
  width: 900px;
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projectPage .links-header {
  font-family: Chakra Petch;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 16px;
  margin-top: 32px;
}

.projectPage .links-body {
  font-size: 24px;
  text-align: center;
}

.projectPage .project-link {
  font-family: Chakra Petch;
  margin: 8px;
}

.projectPage .project-link a {
  color: var(--text);
}
