.navbar {
  position: fixed;
  top: 0;
  min-width: 100%;
  height: 60px;
  background-color: var(--darker);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid var(--text);
  font-family: Chakra Petch;
  z-index: 5;
}

.navbar .content {
  width: 100%;
  max-width: 920px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text);
}

.navbar a {
  padding: 20px;
  padding-bottom: 18px;
  text-decoration: none;
  color: var(--text);
  transition: 0.1s;
}

.navbar a:hover {
  background: var(--darkest);
  transition: 0.1s;
  cursor: pointer;
}

.navbar button {
  background-color: transparent;
  border: none;
  color: var(--text);
  cursor: pointer;
  padding: 20px;
  padding-bottom: 18px;
}

/* If the current screen size is above 550px */
@media only screen and (min-width: 550px) {
  .navbar .left-side {
    display: visible;
  }

  .navbar .section-links {
    display: visible;
  }

  .navbar button {
    display: none;
  }
}

/* If the current screen size is below 550px */
@media only screen and (max-width: 550px) {
  .navbar #open .left-side {
    display: none;
  }

  .navbar #closed .left-side {
    display: visible;
  }

  .navbar #open .section-links {
    display: visible;
  }

  .navbar #closed .section-links {
    display: none;
  }
}
