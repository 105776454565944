.projects {
  width: 100%;
  height: auto;
  /* border-top: 1px solid #f3f1f2; */
  background-color: #181618;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.projects .content {
  border-top: 1px solid #262626;
  width: 1200px;
  max-width: 90%;
  margin-top: 16px;
  color: #f3f1f2;
  animation: pop-in 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.projects .content .header {
  font-family: Chakra Petch;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 12px;
  margin-top: 30px;
}

.projects .content .body {
  font-size: 18px;
  margin: 12px;
  font-family: Open Sans;
}

.projects .project-list {
  margin-top: 50px;
}

.projects .project-list .project {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1060px;
  margin-top: 48px;
  margin-bottom: 48px;  
  animation: pop-in 0.5s forwards;
  animation-delay: 0.6s;  
  opacity: 0;
  /* padding-bottom: 36px;
  border-bottom: 1px solid #262626; */
}

@media only screen and (max-width: 1180px) {
  .projects .project-list .project {
    flex-direction: column;
    justify-content: center;
  }
}

.projects .project-list .project .left-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1060px;
}

@media only screen and (max-width: 1180px) {
  .projects .project-list .project .left-side {
    width: auto;
  }
}

.projects .project-list .project .left-side-content {
  position: relative;
  margin: 16px;
}

.projects .left-side-content .project-type {
  position: absolute;
  flex: 1;
  align-items: center;
  bottom: 4px;
  right: 0px;
  font-size: 32px;
  color: var(--darker);
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  padding: 9px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 12px 0px 12px 0px;
  background-color: var(--text);
  width: 36px;
}

.projects .project-list .project .left-side img {
  height: 280px;
  border-radius: 16px;
  background-color: var(--text);
  padding: 3px;
  box-shadow: 0px 12px var(--lighter);
}

.projects .project-list .project .right-side {
  max-width: 380px;
}

@media only screen and (max-width: 660px) {
  .projects .project-list .project .left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 95%;
    transform: scale(0.65);
  }

  .projects .project-list .project .right-side {
    transform: translateY(-48px);
  }

  .projects .project-list .project {
    margin: 0px;
    transform: translateY(-48px);
  }
}

.projects .project-header {
  margin-top: 12px;
  margin-bottom: 12px;
}

.projects .project-header h1 {
  font-family: Chakra Petch;
  font-size: 32px;
}

.projects .project-body {
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: justify;
  font-size: 18px;
  font-family: Open Sans;
}

.projects .project-caption {
  margin: 16px;
  margin-top: 32px;
  font-size: 18px;
  font-family: Open Sans;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.projects .project-caption .project-type {
  font-size: 32px;
  color: var(--text);
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  padding: 9px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 12px;
  background-color: var(--darkest);
}

.projects .project-learn-more i {
  margin-left: 8px;
}

.projects .learn-more-button {
  display: inline-block;
  text-decoration: none;
  color: var(--text);
  margin: none;
  font-family: Chakra Petch;
  padding: 18px;
  background-color: var(--primary);
  text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
  box-shadow: 0px 6px var(--primary-dark);
  border-radius: 12px;
  cursor: pointer;
}

.projects .learn-more-button:active {
  transform: translateY(6px);
  box-shadow: none;
  transition: transform 34ms;
}

.projects .learn-more-button:hover {
  filter: brightness(90%);
}
