.playATMF {
  width: 100%;
  background-color: var(--darker);
  color: var(--text);
  padding-top: 60px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playATMF iframe {
  margin: 16px;
}

.playATMF .controls {
  border-top: 1px solid var(--dark);
  margin: 16px;
  margin-top: 0px;
  width: 60%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.playATMF .controls .header {
  font-family: Chakra Petch;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 16px;
  margin-top: 16px;
}

.playATMF .controls .body {
    line-height: 1.5em;
    min-width: 500px;
    font-family: Open Sans;
    font-size: 16px;
}

.playATMF .incompatible-on-mobile{
  font-family: Chakra Petch;
  max-width: 90%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 16px;
  margin-top: 128px;
  margin-bottom: 128px;
  display: none;
}

@media only screen and (max-width: 1000px) {
  .playATMF iframe {
    display: none;
  }

  .playATMF .controls {
    display: none;
  }

  .playATMF .incompatible-on-mobile{
    display: flex;
  }
}