.aboutMe {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text);
  background-color: var(--darker);
}

.aboutMe .aboutMeBlock {
  margin-top: 32px;
  margin-bottom: 16px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  width: 900px;
  max-width: 90%;
  align-items: center;
  justify-content: center;
}

.aboutMe .leftSide {
  display: flex;
  flex-direction: column;
  margin: 16px;
  align-items: center;
  justify-content: center;
  animation: pop-in 0.5s forwards;
  animation-delay: 0.1s;
  opacity: 0;
  margin: 32px;
  font-size: 64px;  
}

.aboutMe .leftSide span{
  margin: 8px;
}

.aboutMe .leftSide .bottom-icons {
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .aboutMe .aboutMeBlock {
    display: flex;
    flex-direction: column-reverse;
  }
}

.aboutMe .rightSide {
  max-width: 500px;
  margin: 16px;
  margin-top: 0px;
  overflow: hidden;
  animation: pop-in 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.aboutMe .rightSide .header {
  text-align: center;
  font-size: 32px;
  padding-bottom: 24px;
  font-weight: bold;
  font-family: Chakra Petch;
}

.aboutMe .rightSide .body {
  text-align: center;
  font-size: 18px;
  font-family: Open Sans;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted var(--text);
}

.tooltip .tooltiptext {
  visibility: hidden;
  font-size: 14px;
  min-width: max-content;
  background-color: var(--dark);
  color: var(--text);
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  padding: 5px;
  margin-left: -60px;

  opacity: 0;
  transition: 0.1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  transition: 0.1s;
}
