.notFound {
  width: 100%;
  padding-top: 60px;
  height: 638px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f3f1f2;
  background-color: var(--darker);
}

.notFound h1{
  font-size: 128px;
  font-family: Chakra Petch;
  margin: 8px;
}

.notFound p{
  margin-bottom: 48px;
  font-size: 24px;
  font-family: Open Sans;
}

.notFound .home-button {
  display: inline-block;
  text-decoration: none;
  color: var(--text);
  margin: none;
  font-family: Chakra Petch;
  padding: 18px;
  background-color: var(--primary);
  text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
  box-shadow: 0px 6px var(--primary-dark);
  border-radius: 12px;
  cursor: pointer;
}

.notFound .home-button i{
  margin-left: 8px;
}

.notFound .home-button:active {
  transform: translateY(6px);
  box-shadow: none;
  transition: transform 34ms;
}

.notFound .home-button:hover {
  filter: brightness(90%);
}