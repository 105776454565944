.resume {
  width: 100%;
  /* border-top: 1px solid #f3f1f2; */
  background-color: var(--darker);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.resume .content {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 1200px;
  max-width: 80%;
  color: var(--text);
  font-family: Open Sans;
  animation: pop-in 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.resume .content .divider {
  border-top: 1px solid var(--dark);
}

.resume .header {
  font-family: Chakra Petch;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 12px;
  margin-top: 30px;
}

.resume .body {
  font-size: 18px;
  margin: 12px;
  text-align: center;
}

.resume .resume-link {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pop-in 0.5s forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

.resume .resume-link .content {
  width: 200px;
  max-width: 90%;
}

.resume .resume-link a{
  text-decoration: none;
  color: #f3f1f2;
}

.resume .resume-link .here-button {
  color: var(--text);
  margin: none;
  font-family: Chakra Petch;
  font-size: 24px;
  padding: 24px;
  background-color: var(--primary);
  text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
  box-shadow: 0px 6px var(--primary-dark);
  border-radius: 12px;
  cursor: pointer;
}

.resume .resume-link .here-button:active{
  transform: translateY(6px);
  box-shadow: none;
  transition: transform 34ms;
}

.resume .resume-link .here-button:hover {
  filter: brightness(90%);
}