:root {
  --primary: #e9193c;
  --primary-dark: #b21330;
  --lighter: #a7a7a7;
  --light: #979797;
  --dark: #262626;
  --darker: #181618;
  --darkest: #101010;
  --text: #f3f1f2;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  
}

.App sample{
  color: #F3F1F2;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
