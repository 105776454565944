.footer {
    border-top: 1px solid var(--text);
  background-color: var(--dark);
}

.footer .footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 16px;
}

.footer .socials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;
  margin: 16px;
}

.footer .footer-content i {
  color: var(--dark);
  font-size: 32px;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  /* text-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px; */
  background-color: var(--text);
  box-shadow: 0px 6px var(--lighter);
  border-radius: 12px;
}

.footer .footer-content i:active {
  transform: translateY(6px);
  box-shadow: none;
  transition: transform 34ms;
}

.footer .footer-content i:hover {
  filter: brightness(90%);
}

.footer .footer-content .footer-caption {
    font-size: 12px;
  margin-top: 16px;
  text-align: center;
  color: var(--text);
  line-height: 12px;
}
